@import 'variables';
.common-wp-content {
	strong{
		font-weight: bold;
	}
	.has-medium-font-size{
		font-family: 'Roboto', sans-serif;
		font-size: 1.4em;
		color: #fa4754;
		text-shadow: 2px 2px 0 #000;
		line-height: 1.4;
	}
	hr.wp-block-separator{
		margin: 40px 0;
		$h: 5px;
		border-color: none;
		border: none;
		border-bottom: $h solid #000;
		padding-top: $h * 2;
		background-color: #fff;;
		position: relative;
		&:before{
			content: '';
			width: 100%;
			height: $h;
			top: 0px;
			position: absolute;
			background-color: $fire-engine-red;
		}

	}
	em{
		font-style: italic;
	}

}
