$black-coffee: #32292fff;
$peach-crayola: #ffc09fff;
$glaucous: #6184d8ff;
$magic-mint: #afe0ceff;
$dartmouth-green: #306b34ff;
$red-salsa: #fb6b70;
$teal: #63fed8;
$lime: #51D395;
$green: #8EFD7D;
$green-text: #22a064;

// new colors
$dark-purple: #2e0219ff;
$black-coral: #465362ff;
$fire-engine-red: #EF3340;
$platinum: #dce0d9ff;
$baby-powder: #f7f9f7ff;
$white: #fff;
$black: #000;

$pop: #f3793b;
$bg: $black;
$text: $baby-powder;
$text-dark: #000;
// $cell-bg: $dartmouth-green;
$dem: $glaucous;
$gop: $red-salsa;

$gap: 30px;

$phone-width: 768px;

$btn-color: $green;

.sans{
  font-family: 'Roboto', sans-serif;
}
.serif{
  font-family: 'Source Sans Pro', sans-serif;
}
.btn-color-alt{
  background-color: $btn-color;
}
.btn-primary{
  font-family: inherit !important; /* Replace with your specific font for 'font-btn' */
  display: inline-block !important;
  font-size: 1.5rem !important; /* 'text-2xl' */
  border-radius: 0.5rem !important; /* 'rounded-lg' */
  position: relative !important;
  // padding-top: 0.75rem !important; /* 'pt-3' */
  padding: 15px 30px !important; /* 'p-5' */
  text-transform: uppercase !important;
  color: black !important; /* 'text-black' */
  background-color: $green !important; /* 'btn-color-alt' */
  font-weight: bold !important;
}