@import "variables.scss";

.home{
  width: 100%;
  @extend .sans;
	h2 {
		font-size: 4.5rem;
		font-weight: bold;
		margin-bottom: 12px;
		&:last-of-type {
			margin-bottom: 50px;
		}
	}
	h3 {
		display: inline;
		background-color: $pop;
		color: $black;
		font-size: 3.3rem;
		font-weight: bold;
		line-height: 1.3;
		margin-bottom: 20px;
	}
	> header {
		padding: 50px;
	}
	.partners{
		padding: 50px;
		background-color: $pop;
		color: $black;
    .logos{
			margin-top: 75px;
      display: flex;
      flex-wrap: wrap;
      .img{
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left center;
        filter: grayscale(100%);
        margin-bottom: 15px;
        margin-right: 15px;
        min-width: 300px;
        max-width: 300px;
        height: 70px;
      }
    }
		h3{
			background-color: $black;
			color: $white;
		}
	}
	.letter{
		margin-top: 50px;
	}
	#letter{
		height: 800px;
	}
}
@media (max-width: $phone-width) {
	.home{
		.partners,
		> header{
			padding: 20px;
		}
		h2{
			font-size: 2.5rem;
		}
		h3{
			font-size: 2rem;
		}
		#letter{
			height: 400px;
		}
	}
}