
@import "variables.scss";

.sans{
  font-family: 'Roboto', sans-serif;
}
.serif{
  font-family: 'Source Sans Pro', sans-serif;
}
.btn-color-alt{
  background-color: $btn-color;
}
.btn-primary{
  font-family: inherit !important; /* Replace with your specific font for 'font-btn' */
  display: inline-block !important;
  font-size: 1.5rem !important; /* 'text-2xl' */
  border-radius: 0.5rem !important; /* 'rounded-lg' */
  position: relative !important;
  // padding-top: 0.75rem !important; /* 'pt-3' */
  padding: 15px 30px !important; /* 'p-5' */
  text-transform: uppercase !important;
  color: black !important; /* 'text-black' */
  background-color: $green !important; /* 'btn-color-alt' */
  font-weight: bold !important;
}

html, body {
  background-color: $bg;
  color: $text;
}
a.text-main,
.text-main{
  color: $text-dark !important;
}

.App{
  width: 100%;
  @extend .sans;
  > header{
    text-align: center;
    background-color: #fff;
    padding: 100px 0;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    video{
      position: absolute;
      top: -50px;
      left: 0px;
      width: 100%;
    }
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.5);
      z-index: 1;
    }
    h2,
    h1{
      position: relative;
      z-index: 3;
      // text-shadow: 4px 3px 0 #4f4f4f;
      text-shadow: 4px 3px 0 $dark-purple;
    }
    h2{
      font-size: 1.7em;
    }
  }
  #can-letter-area-ceasefire-now-8{
    // display: none;
  }
  &.submited{
    #can-letter-area-ceasefire-now-8{
      display: block;
    }
    #call_form{
      display: block !important;
    }
  }
  .bottom{
    > .partners{
      display: none;
    }
    // display: flex;
    // > div {
    //   flex: 1;
    //   width: 50%;
    // }
  }
  h1{
    @extend .sans;
    font-size: 6.5rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
  }
  h2{
    @extend .sans;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
  }
  a{
    color: $pop;
    cursor: pointer;
    &:hover{
      color: darken($pop, 10%);
    }
  }
  p{
    // line-height: 1.2;
  }
  .common-wp-content{
    @extend .sans;
    padding: 20px 20px 10px;
    font-size: 1.5rem;
    color: $text-dark;
    line-height: 1.4;
    .continue{
      display: none;
    }
    .content{
      p{
        margin-bottom: 20px;
      }
      h3{
        // margin-top: 40px;
        // font-size: 1.6em;
      }
    }
  }
  .content{
    // max-width: 500px;
  }
  .headline{
    @extend .sans;
    padding: 30px 40px;
    color: $green-text;
    font-weight: 600;
    font-size: 1.7em;
    p{
      line-height: 1.5;
    }
  }
  .filters{
    padding: 0px 20px;
    flex-wrap: wrap;
    color: $black-coral;
    font-size: 1.2rem;
    border-bottom: 4px solid $bg;
    @extend .sans;
    font-weight: 500;
    h3{
      margin-bottom: 15px;
      flex-shrink: 0;
      padding-right: 25px;
    }
    input[type="text"]{
      border-radius: 25px !important;
      border: 2px solid #979797 !important;
      padding: 10px 15px !important;
      font-size: 1.3rem !important;
      width: 100%;
      margin-bottom: 15px;
      &::placeholder{
        font-style: italic;
        // color: $pop;
      }
    }
    input[type="checkbox"]{
      margin-right: 5px;
    }
    label{
      padding-right: 25px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    .box-wrap{
      display: flex;
      padding: 15px 0 0px;
      font-size: 1rem;
      font-weight: bold;
      > div {
        display: flex;
        flex-wrap: wrap;
        label{
          color: $text-dark;
          font-weight: normal;
          margin-bottom: 15px;
          &.support,
          &.full-support{
            padding-right: 45px;
            background: url(./yes.svg) no-repeat right -12px;
            background-size: 50px;
          }
          &.support{
            background-image: url(./shrug.png);
            background-position: right 3px;
            background-size: 20px;
            padding-right: 26px;
          }
        }
      }
    }
    p {
      a{
        color: $text-dark;
        text-decoration: underline;
      }
    }
  }
  .leg-wrap{
    display: flex;
    padding: 0px 0px;
    background-color: #F7F9F7;
    color: $dark-purple;
    flex-direction: row-reverse;
    h2{
      font-size: 2rem;;
      text-align: center;
      padding: 0px $gap;
      font-weight: bold;
    }
    .content{
      flex: 5;
      .filters{
        // display: none;
      }
    }
    .cta{
      padding-right: 25px;
      padding-left: 25px;
      p{
        margin-bottom: 15px;
        &:last-of-type{
          margin-bottom: 0;
        }
      }
    }
  }
  .take-action{
    padding: 40px 0;
    flex-shrink: 0;
    flex: 3;
    background-color: $lime;
    color: $text-dark;
    h2{
      font-size: 3.7rem;
      text-align: left;
      font-weight: 900;
      line-height: 1.0;
      padding-left: 40px;
      padding-right: 40px;
      span {
        display: block;
      }
    }
    .copy,
    .zip-wrap{
      padding-left: 40px;
      padding-right: 40px;
      p{
        line-height: 1.5;
      }
    }
    .copy{
      padding-top: 30px;
      font-size: 1.2em;
      p{
        margin-bottom: 15px;
        &:last-of-type{
          margin-bottom: 0;
        }
      }
    }
    #can_embed_form input[type="text"],
    #can_embed_form input[type="email"],
    #can_embed_form select,
    #can_embed_form span.can_select,
    #can_embed_form textarea#message,
    .text-input{
      border-radius: 25px !important;
      border: 2px solid #979797 !important;
      padding: 10px 15px !important;
      font-size: 1.3rem !important;
      line-height: 22px !important;
      &::placeholder{
        font-style: italic;
        // color: $pop;
      }
    }
   

   
    #can_embed_form.can_float #form_col1,
    #can_embed_form.can_float #form_col2{
      width: 100% !important;
    }
    .btn-round{
      border-radius: 100%;
      background-color: $btn-color;
      display: block;
      padding: 5px 7px;
      font-size: 40px;
      box-shadow: 0px 2px 5px rgba(55,94,52,0.35);
    }
    #call_form{
      // display: none;
    }
    #can_embed_form{
      background-color: transparent !important;
      border: none !important;
      #can_embed_form_inner > h4,
      h2.line{
        display: none !important;
      }
      input[type="submit"]{
        font-family: inherit !important; /* Replace with your specific font for 'font-btn' */
        display: inline-block !important;
        font-size: 1.5rem !important; /* 'text-2xl' */
        border-radius: 0.5rem !important; /* 'rounded-lg' */
        position: relative !important;
        // padding-top: 0.75rem !important; /* 'pt-3' */
        padding: 10px !important; /* 'p-5' */
        text-transform: uppercase !important;
        color: black !important; /* 'text-black' */
        background-color: $green !important; /* 'btn-color-alt' */
        font-weight: bold !important;
        width: 100% !important; /* 'w-full' */
        &:disabled{
          opacity: 0.8;
        }
      }
      #action_welcome_message #action_welcome_message_inner{
        background-color: transparent !important;
        border: 1px solid $green !important;
      }
      #logo_wrap{
        background-color: transparent !important;
      }
      #action_info{
        display: none;
      }
    }
  }
  .partners{
    margin-top: 45px;
    padding: 30px 40px;
    border-top: 4px solid $green-text;
    h3{
      @extend .sans;
      font-size: 2.5rem;
      margin-bottom: 20px;
      font-weight: 300;
    }
    .logos{
      display: flex;
      flex-wrap: wrap;
      .img{
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left center;
        filter: grayscale(100%);
        margin-bottom: 15px;
        margin-right: 15px;
        min-width: 220px;
        max-width: 200px;
        height: 70px;
      }
    }
  }
  .info sup{
    color: $lime;
  }
  .summary{
    padding: 20px 20px 20px;
    font-size: 1.3rem;
    line-height: 1.5;
    @extend .sans;
    border-bottom: 4px solid $bg;
    // font-weight: bold;
    .big{
      color: $lime;
      font-size: 2.0rem;
      font-weight: 300;
      display: inline-block;
      padding-right: 5px;
    }
    b{
      font-weight: 900;
      line-height: 1;
    }
    em{
      font-weight: 900;
      font-size: 2.0rem;
      line-height: 1.2;
      sup{
        color: $lime;
        display: inline-block; /* Allows the use of transform */
        transform: translateY(8px); /* Adjust the value to move it lower */
      }
    }
    > div {
      margin-top: 20px;
      font-size: 1.1rem;
      color: $black-coral;
      b{
        font-size: 1.3rem;
        color: $lime;
      }
    }
  }
  .legs{
    margin-top: 20px;
    margin-bottom: 40px;
    width: 100%;
    a {
      color: #294892;
      font-weight: 300;
    }
    .title{
      color: $black-coral;
    }
    .name{
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 5px;
      @extend .sans;
    }
    > div{
      width: 100%;
      display: flex;
      align-items: center;
      > span {
        width: 50%;
        &.check{
          text-align: center;
          width: 25%;
        }
      }
      &.header{
        // background-color: $cell-bg;
        padding: 15px 20px;
        span{
          @extend .sans;
          font-weight: 700;
          font-size: 1.2rem;
          line-height: 1.3;
          span.title{
            display: block;
          }
          label{
            font-weight: normal;
            font-size: 0.7em;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0 5px;
          }
        }
      }
      &.leg{
        padding: 15px 20px;
        font-size: 1.2rem;
        .check{
          font-size: 0.8em;
          position: relative;
          &.yes,
          &.no{
            &:before{
              content: '';
              background: url(./no.svg) no-repeat -36px -15px;
              background-size: 90px; 
              display: inline-block;
              width: 50px;
              height: 40px;
            }
          }
          &.yes{
            &:before{
              position: relative;
              background-image: url(./yes.svg);
              background-position-x: -2px;
            }
            &.partial{
              &:before{
                background-image: url(./shrug.png);
                background-size: 70%;
                background-position: center 15px;
              }
            }
          }
        }
      }
    }
  }
}

// tweetswarm styling
body > section {
  > header {
    display: none;
  }
  &.main{
    > .container{
      padding-left: 0;
      padding-right: 0;
      #zips{
        label{
          font-family: 'Roboto', sans-serif;
          font-size: 1.4em;
          color: #fa4754;
          text-shadow: 2px 2px 0 #000;
          line-height: 1.4;
        }
        input[type="text"]{
          border: 2px solid #000;
          background-color: $text;
          border-radius: 0px;
          color: #000;
          height: auto;
          padding: 12px;
          &::placeholder{
            font-style: italic;
            // color: $pop;
          }
        }
      }
      .btn-primary{
        background-color: #fa4754;
        border-color: #fa4754;
        &:hover{
          background-color: darken(#fa4754, 10%);
          border-color: darken(#fa4754, 10%);
        }
      }
    }
    .headline-row{
      display: none;
    }
  }
  > footer{
    display: none;
  }
}

.overlay .modal{
  @extend .serif;
  color: $text-dark;
  font-size: 1.5rem;
  line-height: 1.4;
}

@media (min-width: $phone-width + 1) {
  .App{
    .total-take-action{
      display: none;
    }
    .take-action-wrap{
      position: sticky;
      top: 20px;
      max-height: 100vh;
      overflow: auto;
    }
  }

}
@media (max-width: $phone-width) {
  .App{
    > header {
      padding: 60px 0 50px;
      h1{
        font-size: 3rem;
      }
      video{
        top: -75px;
        height: 75vw;
      }
    }
    .summary{
      padding-right: 30px;
      padding-left: 30px;
      text-align: center;
      .big{
        display: block;
        font-size: 3em;
      }
      em{
        padding: 5px 0;
        display: block;
      }
    }
    .take-action{
      background-color: $lime;
      padding-left: 40px;
      padding-right: 40px;
      h2{
        font-size: 3rem;
        padding-left: 0;
        padding-right: 0;
        span {
          display: inline;
        }
      }
      .copy,
      .zip-wrap{
        padding-left: 0;
        padding-right: 0;
        p{
          line-height: 1.5;
        }
      }
      .partners{
        display: none;
      }
    }
    .bottom{
      flex-direction: column;
      > div {
        width: 100%;
        &.partners{
          margin-top: 0;
          display: block;
          padding: 40px;
          background-color: $lime;
        }
      }
      &.continueReading{
        .common-wp-content{
          .content{
            height: auto;
            overflow: auto;
          }
          .continue{
            display: none;
          }
        }
      }
      &:not(.continueReading) {
        .common-wp-content{
          .content{
            position: relative;
          }
          .has-medium-font-size{
            position: absolute;
            top: 0px;
            width: 100%;
          }
        }
      }
    }
    .common-wp-content{
      padding: 25px;
      align-items: center;
      flex-direction: column;
      .content{
        overflow: hidden;
        font-size: 1.6rem;
        h3{
          line-height: 1.1;
        }
      }
      .continue{
        display: block;
        text-align: right;
        text-transform: uppercase;
        width: 100%;
        padding-top: 5px;
        padding-right: 30px;
        background: url(./down-carrot.svg) no-repeat center right 8px;
      }
    }
    .left-side{
      .filters{
        display: none;
      }
    }
    .leg-wrap{
      flex-direction: column-reverse;
      .take-action{
        display: none;
      }
      .App .filters input[type=text]{
        margin-bottom: 30px;
      }
      .content .filters{
        display: block;
        padding: 0px 15px 0px;
        .box-wrap{
          display: block;
          label{
            font-size: 1.2rem;
            width: 50%;
            display: inline-block;
            &.full-support{
              background-size: 85px;
              background-position-y: -24px;
            }
            &.support{
              background-size: 40px;
            }

          }
        }
      }
      .legs{
        // margin-top: 0;
        .header{
          span.check{
            width: 50%;
            &:last-child{
              display: none;
            }
          }

        }
      }
    }

  }
}
@media (max-width: 375px) {
  .App > header video{
    height: 100vh;
  }
}